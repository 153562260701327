import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { selectFieldProps, textFieldProps } from '../../../shared/constants'
import FileUploader from '../../../shared/FileUploader'
import Select from 'react-select'

export default function CreateTeamDialog({ open, close }) {
    const { GetAppUsers, createTeam } = useDb()
    const users = GetAppUsers()
   
    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    })
    
    const handleClose = () => {
        setState({
            loading: false,
            error: null,
            data: {}
        })
        close()
    }

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const getFile = (file) => {
        setState({
            ...state,
            data: {
                ...state.data,
                logo_url: file.url
            }
        })
    }

    const handleSelect = (v, e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v.value
            }
        })
    }

    const handleSubmit = async () => {
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            await createTeam({...state.data, owner: "oFFnLJP2Sce543QkiDMBKH1424q2"})
            handleClose()
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <Dialog open={open} close={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Create Team</DialogTitle>
            <DialogContent>
                <TextField 
                    {...textFieldProps}
                    label="Team Name"
                    name="name"
                    onChange={handleChange}
                />
                <FileUploader
                    sendFile={getFile}
                    fileType="Logo (PNG)"
                />
                <Select
                    options={users}
                    placeholder="Select Captain ..."
                    {...selectFieldProps}
                    onChange={handleSelect}
                    name="captain_id"
                />
                <Select
                    options={users}
                    placeholder="Select Vice Captain ..."
                    {...selectFieldProps}
                    onChange={handleSelect}
                    name="vice_id"
                />
                <Select
                    options={users}
                    placeholder="Select Third Angler (Optional) ..."
                    {...selectFieldProps}
                    onChange={handleSelect}
                    name="third_id"
                />
            </DialogContent>
            <DialogActions>
                {
                    state.error && <Typography color="secondary">{state.error}</Typography>
                }
                <Button
                    color="secondary"
                    onClick={handleClose}
                    disabled={state.loading}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={ state.loading || !state.data.name || !state.data.logo_url || !state.data.captain_id || !state.data.vice_id }
                    onClick={handleSubmit}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}
