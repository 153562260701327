import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { textFieldProps } from "../../../shared/constants";

export default function AddPegZone({ open, close, teamId }) {
  const { updateTeamBoard, updateTeam } = useDb();

  const [state, setState] = React.useState({
    loading: false,
    error: null,
    data: {},
  });

  const handleClose = () => {
    setState({
      loading: false,
      error: null,
      data: {},
    });
    close();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState({
      ...state.data,
      loading: true,
      error: null,
    });
    try {
      await updateTeam(teamId, state.data);
      await updateTeamBoard(teamId, state.data);
      handleClose();
    } catch (err) {
      setState({
        ...state.data,
        loading: false,
        error: err.message,
      });
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Peg & Zone</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            {...textFieldProps}
            label="Peg"
            required
            name="peg"
            onChange={handleChange}
          />
          <TextField
            {...textFieldProps}
            label="Zone"
            required
            name="zone"
            onChange={handleChange}
          />
          {state.error && (
            <Typography color="secondary">{state.error}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
