import { Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext';
import logo from '../../img/WCC22.png'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        margin: '10px 0',
        color: '#fff'
    },
    btn: {
        margin: '15px 0'
    },
    img: {
        margin: '20px 0'
    }
}))

export default function SignUp() {
    const classes = useStyles()
    const history = useHistory()
    const { signUp } = useAuth()

    const [state, setState] = useState({
        error: null,
        loading: false,
        data: {}
    })

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setState({
            ...state,
            error: null ,
            loading: true
        })
        try {
            await signUp(state.data)
            history.push('/')
        }
        catch(err) {
            console.log(err.message)
            setState({
                ...state,
                error: err.message,
                loading:false
            })
        }
    }

    return (
        <div>
            <Container maxWidth="xs">
                <div className={classes.paper}>
                    <Typography className={classes.title}>
                        Create an Account
                    </Typography>
                    <img src={logo} width={200} className={classes.img}/>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Email"
                            type="email"
                            name="email"
                            margin="normal"
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            required
                        />
                        <TextField
                            label="Password"
                            type="password"
                            name="password"
                            margin="normal"
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            required
                        />
                        <TextField
                            label="Full Name"
                            name="display_name"
                            margin="normal"
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            required
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            className={classes.btn}
                            size="large"
                            type="submit"
                            disabled={state.loading}
                        >
                            Create Account
                        </Button>
                    </form>
                    <Grid container>
                        <Grid item xs>
                            <Link to="/" style={{ color: '#fff' }}>
                                Back to Login
                            </Link>
                        </Grid>
                    </Grid>
                </div>
                { state.error && <Typography color="secondary">{state.error}</Typography> }
            </Container>
        </div>
    )
}
