import React from 'react'
import { Route } from 'react-router-dom'
import CustomerHome from '../components/customer/CustomerHome'
import LogCatch from '../components/customer/log-catch/LogCatch'
import MarshallCatchLog from '../components/customer/marshall-views/MarshallCatchLog'
import CatchHistory from '../components/customer/my-catches/CatchHistory'
import AnglerOfTheYear from '../components/leaderboards/angler-of-year/AnglerOfTheYear'
import BiggestFishBoard from '../components/leaderboards/biggest-fish/BiggestFishBoard'
import TeamsThreeBoard from '../components/leaderboards/teams-three/TeamsThreeBoard'

export default function CustomerRoutes() {
    return (
        <>
            <Route exact path="/" component={CustomerHome} />
            <Route exact path="/log-catch" component={LogCatch} />
            <Route exact path="/catch-history" component={CatchHistory} />
            <Route path="/angler-year" component={AnglerOfTheYear} />
            <Route path="/three-board" component={BiggestFishBoard} />
            <Route path="/marshall-catch" component={MarshallCatchLog} />
            <Route path="/teams-board" component={TeamsThreeBoard} />
        </>
    )
}
