import React from "react";
import ArticleList from "./ArticleList";

export default function Articles() {
  return (
    <div>
      <h1>Articles</h1>
      <ArticleList />
    </div>
  );
}
