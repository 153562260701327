export const compTypes = [
  { value: "Individuals", label: "Individuals" },
  { value: "Teams", label: "Teams" },
];

export const ruleTypes = [
  { value: 1, label: "Biggest Fish" },
  { value: 3, label: "3 Biggest Fish" },
  { value: 5, label: " 5 Biggest Fish" },
  { value: 0, label: " Total Bag" },
];

export const compFormats = [
  { value: "Approval", label: "Approval" },
  { value: "Marshalls", label: "Marshalls" },
  { value: "Open", label: "Open" },
];
