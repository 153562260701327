import { Avatar, Card, CardHeader } from '@material-ui/core'
import React from 'react'
import moment from 'moment'
import { Star } from '@material-ui/icons'

export default function SingleCatch({ fish }) {
    const { species, weight, venue, entered, photo_url, timestamp } = fish
    return (
        <div style={{ marginBottom: 10 }}>
            <Card>
                <CardHeader 
                    title={`${species} - ${weight} kg`}
                    avatar={
                        <Avatar
                            src={photo_url}
                        />
                    }
                    subheader={`${venue} - ${moment(timestamp.toDate()).fromNow()}`}
                    action={ entered ? <Star /> : null }
                />

            </Card>
        </div>
    )
}
