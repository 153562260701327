import { Button } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import CatchDetail from '../CatchDetail'
import FishCard from '../FishCard'

export default function AnglerEntries() {
    const { anglerId } = useParams()
    const history = useHistory()
    const { GetPublicFishForAngler } = useDb()
    const entries = GetPublicFishForAngler(anglerId)
    
    const [open, setOpen] = useState(false)
    const [selectedCatch, setSelectedCatch] = useState()

    const handleOpen = (f) => {
        setSelectedCatch(f)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setSelectedCatch(null)
    }

    return (
        <div>
            <div className="back-link">
                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<ArrowBack />}
                    onClick={() => history.goBack()}
                >
                    Back
                </Button>
            </div>
            
            {
                entries && entries.map((f, i) => (
                    <FishCard 
                        entry={f}
                        pos={i+1}
                        key={f.id}
                        openDetail={() => handleOpen(f)}
                    />
                ))
            }
            {
                selectedCatch && <CatchDetail
                    open={open}
                    close={handleClose}
                    entry={selectedCatch}
                />
            }
        </div>
    )
}
