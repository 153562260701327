import { Button, Container, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center'
    }
}))

export default function CustomerHome() {
    const classes = useStyles()
    const { currentUser } = useAuth()
    return (
        <div >
            <Container className={classes.container}>
                <h3>Welcome { currentUser.display_name || currentUser.email }</h3>
                <Link to={'/log-catch'}>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        size="large"
                    >
                        Log Personal Catch
                    </Button>
                </Link>
                <br/><br/>
                <Link to={'/catch-history'}>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        size="large"
                    >
                        My Catches
                    </Button>
                </Link>
                <br/><br/>
                <Link to={'/teams-board'}>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        size="large"
                    >
                        Teams Leaderboard
                    </Button>
                </Link>
                <br/><br/>
                {
                    currentUser.marshall && <Link to={'/marshall-catch'}>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        size="large"
                    >
                        Marshalls - Log Catch
                    </Button>
                </Link>
                }
            </Container>
        </div>
    )
}
