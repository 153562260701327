import React, { useEffect, useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@material-ui/core";
import TeamCheckCard from "./TeamCheckCard";

export default function ParticipantCard({ pid }) {
  const { getStandardDoc } = useDb();
  const [part, setPart] = useState();
  const loadUser = async () => {
    const user = await getStandardDoc("users", pid);
    setPart(user);
  };

  useEffect(() => {
    return loadUser();
  }, [pid]);

  return !part ? (
    <LinearProgress />
  ) : (
    <div>
      <Card style={{ marginBottom: 5 }}>
        <CardHeader
          title={part.display_name}
          avatar={<Avatar src={pid.photo_url} />}
        />
        <CardContent>
          {part.team_id && <TeamCheckCard teamId={part.team_id} />}
        </CardContent>
      </Card>
    </div>
  );
}
