import React, { useEffect, useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { Button, CircularProgress } from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";

export default function CompCatchVideo({ catchId }) {
  const { getCatchVids } = useDb();
  const [video, setVideo] = useState();

  const loadVideo = async () => {
    const v = await getCatchVids(catchId);
    console.log(v);
    if (v && v[0]) {
      setVideo(v[0]);
    }
  };

  useEffect(() => {
    return loadVideo();
  }, [catchId]);

  return (
    <div>
      {video && (
        <Button
          href={video.video_url}
          target="_blank"
          color="primary"
          variant="contained"
          startIcon={<PlayArrow />}
        >
          Play Video
        </Button>
      )}
    </div>
  );
}
