import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import CatchDetail from '../CatchDetail'
import FishCard from '../FishCard'

export default function BiggestCommon() {
    const { GetBiggestCommons } = useDb()
    const entries = GetBiggestCommons()

    const [open, setOpen] = useState(false)
    const [selectedCatch, setSelectedCatch] = useState()

    const handleOpen = (f) => {
        setSelectedCatch(f)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setSelectedCatch(null)
    }
    
    return (
        <div>
            {
                entries && entries.map((f, i) => (
                    <FishCard 
                        entry={f}
                        pos={i+1}
                        key={f.id}
                        openDetail={() => handleOpen(f)}
                    />
                ))
            }
            {
                selectedCatch && <CatchDetail
                    open={open}
                    close={handleClose}
                    entry={selectedCatch}
                />
            }
        </div>
    )
}
