import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import CarpDataGrid from "../../../shared/datagrid/CarpDataGrid";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";

export default function AddAnglerToComp({ open, close, handleAnglerAdd }) {
  const { GetCollection } = useDb();
  const users = GetCollection("users");

  const rows =
    users &&
    users.map((user) => ({
      ...user,
      uid: user.id,
    }));

  const columns = [
    { field: "display_name", headerName: "Name", width: 180 },
    { field: "email", headerName: "Email", width: 180 },
    {
      field: "team_id",
      headerName: "Team",
      sortable: false,
      width: 180,
      renderCell: (params) => {
        return (
          <Link to={`/edit-team/${params.row.team_id}`}>
            <b>{params.row.team_id}</b>
          </Link>
        );
      },
    },
    {
      field: "action",
      headerName: "Add",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => handleAnglerAdd(params.row.id)}
            color="primary"
            startIcon={<Add />}
          >
            Add
          </Button>
        );
      },
    },
  ];

  return (
    <Dialog open={open} onClose={close} maxWidth="lg" fullWidth>
      <DialogContent>
        {users && <CarpDataGrid data={rows} columns={columns} />}
      </DialogContent>
    </Dialog>
  );
}
