import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { storage } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import { CloudUpload } from "@material-ui/icons";
import { isBlurhashValid } from "blurhash";
import { getBlurHash } from "./helpers";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function FileUploader({ sendFile, fileType }) {
  const { currentUser } = useAuth();
  const storageRef = storage.ref("users").child(currentUser.uid);
  const [fileSelected, setFileSelected] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileSelected(true);
    }
  };

  const handleUploadStart = async () => {
    setIsUploading(true);
    setProgress(0);
    const fileName = Math.random().toString(36).slice(-10);
    const uploadTask = storageRef
      .child(`documents/${fileName}/${file.name}`)
      .put(file);

    // Get blurhash
    let blurHash = "LIHwGm}l0}Rl0$EjIUWB00S7^4oe";
    // try {
    //   blurHash = await getBlurHash(file);
    //   if (!isBlurhashValid(blurHash)) {
    //     blurHash = undefined;
    //   }
    // } catch (e) {
    //   console.error("Failed to calculate blurhash", e);
    // }

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var upProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(upProgress);
      },
      (error) => {
        setError("Error during File Upload");
        setIsUploading(false);
        setFileSelected(false);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          setProgress(100);
          setIsUploading(false);
          setFileSelected(false);
          const fileInfo = {
            url,
            fileName: file.name,
          };
          if (blurHash) {
            fileInfo.blurHash = blurHash;
          }
          sendFile(fileInfo);
        });
      }
    );
  };

  return (
    <Card style={{ width: "100%", margin: "20px 0" }}>
      <CardContent>
        Upload {fileType}:<br />
        <input type="file" onChange={handleFileChange} />
        <LinearProgressWithLabel value={progress} />
        {error !== "" ? `Error Uploading file` : null}
      </CardContent>
      <CardActions className="iso-right">
        <Button
          disabled={!fileSelected || isUploading}
          onClick={handleUploadStart}
          startIcon={<CloudUpload />}
          variant="contained"
          color="primary"
        >
          Upload
        </Button>
      </CardActions>
    </Card>
  );
}
