import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDb } from "../../../contexts/DatabaseContext";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import BackLink from "../../../shared/BackLink";
import { Info } from "@material-ui/icons";
import CompCatchVideo from "./CompCatchVideo";

export default function CompCatchDetail() {
  const { catchId } = useParams();
  const { getStandardDoc } = useDb();
  const [fishCatch, setFishCatch] = useState();

  const loadCatch = async () => {
    const fish = await getStandardDoc("catches", catchId);
    setFishCatch(fish);
  };

  useEffect(() => {
    return loadCatch();
  }, [catchId]);

  return !fishCatch ? (
    <LinearProgress />
  ) : (
    <div>
      <BackLink />
      <Card>
        <CardHeader title="Fish Details" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader
                  title="Catch Details"
                  avatar={
                    <Avatar>
                      <Info />
                    </Avatar>
                  }
                />
                <CardContent>
                  <Typography>Species: {fishCatch.species}</Typography>
                  <Typography>Weight: {fishCatch.weight} kg</Typography>
                  <Typography>
                    Timestamp: {fishCatch.timestamp?.toDate().toString()}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button color="primary" variant="contained">
                    Edit Weight
                  </Button>
                  <Button color="primary" variant="contained">
                    Decline Catch
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <CompCatchVideo catchId={catchId} />
              <img src={fishCatch.photo_url} width={"100%"} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
