import { createTheme, ThemeProvider } from "@material-ui/core";
import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { DatabaseProvider } from "../contexts/DatabaseContext";
import PublicRoutes from "../routes/PublicRoutes";
import AdminView from "./admin/AdminView";
import CustomerPage from "./customer/CustomerPage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fdc42c",
      contrastText: "#003869",
    },
    type: "dark",
    background: {
      default: "#0a5b3a",
      paper: "#022718",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

export default function Wrapper() {
  const { currentUser } = useAuth();
  return (
    <ThemeProvider theme={theme}>
      {
        <DatabaseProvider>
          {currentUser ? (
            currentUser.admin ? (
              <AdminView />
            ) : (
              <CustomerPage />
            )
          ) : (
            <PublicRoutes />
          )}
        </DatabaseProvider>
      }
    </ThemeProvider>
  );
}
