import { Avatar, Card, CardContent, CardHeader, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import React from 'react'

export default function BigThreeCard({ entry, pos }) {
    const { combinedWeight, display_name, entries } = entry
    return (
        <Card style={{ marginBottom: 10 }}>
            <CardHeader
                title={display_name}
                avatar={
                    <Avatar>
                        { pos }
                    </Avatar>
                }
                subheader={`Combined Weight: ${combinedWeight.toFixed(2)} kg`}
            />
            <CardContent>
                <List>
                    {
                        entries && entries.map(f => (
                            <ListItem divider key={f.id}>
                                <ListItemAvatar>
                                    <Avatar src={f.photo_url} />
                                </ListItemAvatar>
                                <ListItemText>
                                    { `${f.species} - ${f.weight} kg` }
                                </ListItemText>
                            </ListItem>
                        ))
                    }
                </List>
            </CardContent>
        </Card>
    )
}
