import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import AnglerEntry from './AnglerEntry'

export default function AnglerOfTheYear() {
    const { GetAnglerScores } = useDb()
    const entries = GetAnglerScores()

    return (
        <div>
            {
                entries && entries.map((a, index) => (
                    <AnglerEntry key={a.id} entry={a} pos={index+1} />
                ))
            }
        </div>
    )
}
