import React from "react";
import ParticipantCard from "./ParticipantCard";
import { Typography } from "@material-ui/core";

export default function CompParticipants({ participantRefs, participantIds }) {
  return (
    <div>
      <Typography>All Anglers</Typography>

      {participantIds &&
        participantIds.map((pid) => <ParticipantCard pid={pid} />)}
    </div>
  );
}
