import React, { useState } from "react";
import moment from "moment";
import CarpDataGrid from "../../../shared/datagrid/CarpDataGrid";
import CatchDetail from "./CatchDetail";
import { IconButton } from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import CatchDetailViewOnly from "./CatchDetailViewOnly";
import { Check, LocationOn, ThumbDown } from "@material-ui/icons";
import AddGeo from "./AddGeo";

export default function FishList({ data, allView }) {
  const [selectedFish, setSelectedFish] = useState();
  const [open, setOpen] = useState(false);
  const [geoOpen, setGeoOpen] = useState(false);

  const openDialog = (fish) => {
    setSelectedFish(fish);
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
    setSelectedFish(null);
  };

  const openGeoDialog = (fish) => {
    setSelectedFish(fish);
    setGeoOpen(true);
  };

  const closeGeoDialog = () => {
    setGeoOpen(false);
    setSelectedFish(null);
  };

  const rows =
    data &&
    data.map((f) => ({
      id: f.id,
      species: f.species,
      date: moment(f.timestamp.toDate()).fromNow(),
      weight: `${f.weight} kg`,
      photoUrl: f.photo_url || "",
      uid: f.uid,
      venue: f.venue,
      angler: f.display_name,
      approved: f.approved || false,
      exact_location: f.exact_location || false,
    }));

  const columns = [
    { field: "date", headerName: "Caught:", width: 200 },
    { field: "species", headerName: "Species", width: 200 },
    { field: "weight", headerName: "Weight", width: 200 },
    { field: "angler", headerName: "Angler", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 140,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <IconButton onClick={() => openDialog(params.row)}>
              <Search />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: "geo",
      headerName: "Add Geo",
      width: 140,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <IconButton onClick={() => openGeoDialog(params.row)}>
              <LocationOn />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: "hasGeo",
      headerName: "Has Geo ?",
      width: 140,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params.row.exact_location ? <Check /> : <ThumbDown />}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <CarpDataGrid data={rows} columns={columns} />
      {selectedFish ? (
        allView ? (
          <CatchDetailViewOnly
            open={open}
            close={closeDialog}
            fish={selectedFish}
          />
        ) : (
          <CatchDetail open={open} close={closeDialog} fish={selectedFish} />
        )
      ) : null}

      {selectedFish && (
        <AddGeo open={geoOpen} close={closeGeoDialog} fish={selectedFish} />
      )}
    </div>
  );
}
