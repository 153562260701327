import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import BigThreeCard from './BigThreeCard'

export default function BiggestFishBoard() {
    const  { GetBiggestThree } = useDb()
    const entries = GetBiggestThree()
    console.log(entries)
    return (
        <div>
            {
                entries && entries.map((a, i) => (
                    <BigThreeCard key={a.id} entry={a} pos={i + 1} />
                ))
            }
        </div>
    )
}
