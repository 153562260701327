import { Button } from '@material-ui/core'
import { PersonAdd } from '@material-ui/icons'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import CarpDataGrid from '../../../shared/datagrid/CarpDataGrid'
import AddMarshallDialog from './AddMarshallDialog'

export default function Marshalls() {
    const { GetMarshalls } = useDb()
    const marshalls = GetMarshalls()
    const [addOpen, setAddOpen] = useState(false)

    const rows = marshalls && marshalls.map(m => ({
        id: m.id,
        name: m.display_name,
        email: m.email
    }))

    const columns = [
        { field: "name", headerName: "Name", width: 300 },
        { field: "email", headerName: "Email", width: 300 },
    ]

    return (
        <div>
            <h1>Marshalls</h1>
            <Button
                color="primary"
                variant="contained"
                startIcon={<PersonAdd />}
                onClick={() => setAddOpen(true)}
            >
                Add Marshall
            </Button>
            <br/><br/>
            <CarpDataGrid
                data={rows}
                columns={columns}
            />
            <AddMarshallDialog
                open={addOpen}
                close={() => setAddOpen(false)}
            />
        </div>
    )
}
