import { Avatar, Card, CardHeader, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    pos: {
        background: '#fff',
        color: theme.palette.primary.main
    }
}))

export default function FishCard({ entry, pos, openDetail }) {
    const classes = useStyles()

    const { display_name, weight, species, photo_url } = entry


    return (
        <Card style={{ marginBottom: 10, cursor: 'pointer' }} onClick={() => openDetail(entry)}>
            <CardHeader 
                title={`${species} - ${weight} kg`}
                avatar={
                    <Avatar
                        src={photo_url}
                    />
                }
                subheader={display_name}
                action={   
                    <Avatar className={classes.pos}>
                        { pos }
                    </Avatar>
                }
            />
        </Card>
    )
}
