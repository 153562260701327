import React, { useEffect, useState } from "react";
import BackLink from "../../../shared/BackLink";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { People, Person } from "@material-ui/icons";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDb } from "../../../contexts/DatabaseContext";
import TeamMember from "./TeamMember";
import AddAnglerToTeam from "./AddAnglerToTeam";

export default function EditTeam() {
  const { teamId } = useParams();
  const { getStandardDoc, updateStandardDoc } = useDb();
  const [addOpen, setAddOpen] = useState(false);
  const [addingPosition, setAddingPosition] = useState();

  const [team, setTeam] = useState();

  const loadTeam = async () => {
    const t = await getStandardDoc("teams", teamId);
    setTeam(t);
  };

  useEffect(() => {
    return loadTeam();
  }, [teamId]);

  console.log(team);

  const openAddDialog = (pos) => {
    setAddingPosition(pos);
    setAddOpen(true);
  };

  const closeAdding = () => {
    setAddOpen(false);
    setAddingPosition(null);
  };

  return !team ? (
    <LinearProgress />
  ) : (
    <div>
      <BackLink />
      <h1>Edit Team</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              avatar={
                team.logo_url ? (
                  <Avatar src={team.logo_url} />
                ) : (
                  <Avatar>
                    <People />
                  </Avatar>
                )
              }
              title="Team Members"
            />
            <CardContent>
              <Typography>Captain: </Typography>
              {team.captain_id ? (
                <TeamMember
                  memberId={team.captain_id}
                  teamId={teamId}
                  refName="captain_ref"
                  idName="captain_id"
                />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    openAddDialog({
                      idName: "captain_id",
                      refName: "captain_ref",
                    });
                  }}
                >
                  Add Captain
                </Button>
              )}
              <Typography>Vice-Captain: </Typography>
              {team.vice_id ? (
                <TeamMember
                  memberId={team.vice_id}
                  teamId={teamId}
                  refName="vice_captain_ref"
                  idName="vice_id"
                />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    openAddDialog({
                      refName: "vice_captain_ref",
                      idName: "vice_id",
                    });
                  }}
                >
                  Add Vice Capt.
                </Button>
              )}
              <Typography>Third Angler: </Typography>
              {team.third_id ? (
                <TeamMember
                  memberId={team.third_id}
                  teamId={teamId}
                  refName="third_ref"
                  idName="third_id"
                />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    openAddDialog({
                      idName: "third_id",
                      refName: "third_ref",
                    });
                  }}
                >
                  Add Third Angler
                </Button>
              )}
              <Typography>Fourth Angler: </Typography>
              {team.fourth_id ? (
                <TeamMember
                  memberId={team.fourth_id}
                  teamId={teamId}
                  refName="fourth_ref"
                  idName="fourth_id"
                />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    openAddDialog({
                      idName: "fourth_id",
                      refName: "fourth_ref",
                    });
                  }}
                >
                  Add Fourth Angler
                </Button>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {addingPosition && (
        <AddAnglerToTeam
          teamId={teamId}
          open={addOpen}
          close={closeAdding}
          position={addingPosition}
        />
      )}
    </div>
  );
}
