import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { textFieldProps, selectFieldProps } from "../../../shared/constants";
import Select from "react-select";
import { compTypes, ruleTypes, compFormats } from "./compConfig";
import { useDb } from "../../../contexts/DatabaseContext";
import FileUploader from "../../../shared/FileUploader";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { parseLatLong } from "../../maps/mapsHelpers";
import VenueMapPreview from "../../maps/VenueMapPreview";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext"

export default function CreateCompetition() {
  const {
    GetCollection,
    getFirestoreGeoPoint,
    addStandardDoc,
    updateStandardDoc,
  } = useDb();
  const { currentUser } = useAuth();
  const history = useHistory();
  const venues = GetCollection("venues");
  const users = GetCollection("users");
  const [comp, setComp] = useState({});
  const [coords, setCoords] = useState({
    lat: 0,
    long: 0,
  });
  const [loading, setLoading] = useState(false);

  const handleText = (e) => {
    setComp({ ...comp, [e.target.name]: e.target.value });
  };

  const handleSelect = (v, e) => {
    setComp({ ...comp, [e.name]: v.value });
  };

  const handleRuleTypeSelect = (v) => {
    setComp({ ...comp, rule_type: v.label, catch_limit: v.value });
  };

  const getLogo = (fileObj) => {
    setComp({ ...comp, logo_url: fileObj.url });
  };
  const getBackground = (fileObj) => {
    setComp({
      ...comp,
      background_url: fileObj.url,
      background_blurhash: "LIHwGm}l0}Rl0$EjIUWB00S7^4oe",
    });
  };

  const handleGoogleMapLatLng = (e) => {
    try {
      const coordinates = parseLatLong(e.target.value);
      setCoords(coordinates);
      setComp({
        ...comp,
        latitude: coordinates.lat.toString(),
        longitude: coordinates.long.toString(),
      });
    } catch (err) {
      window.alert("Invalid coordinates");
    }
  };

  const handleViewPortChange = (newViewPort) => {
    setComp({
      ...comp,
      latitude: newViewPort.latitude.toString(),
      longitude: newViewPort.longitude.toString(),
      zoom: newViewPort.zoom,
    });
    setCoords({
      lat: newViewPort.latitude,
      long: newViewPort.longitude,
    });
  };

  const handleVenueSelect = (v) => {
    setComp({
      ...comp,
      venue_id: v.value,
      venue: v.label,
    });
  };

  const handleOwnerSelect = (v) => {
    setComp({
      ...comp,
      owner: v.value,
      ownerRef: v.ref,
    });
  };

  const handleLogTypeSelect = (v) => {
    setComp({
      ...comp,
      log_type: v.value,
      allow_self_entry: v.value === "Marshalls" ? false : true,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const location = getFirestoreGeoPoint(comp.latitude, comp.longitude);
    const newComp = {
      ...comp,
      latitude: comp.latitude.toString(),
      longitude: comp.longitude.toString(),
      competition_category: "official",
      location,
      admins: [currentUser.uid],
      addedBy: currentUser.uid
    };
    setLoading(true);
    try {
      const compId = await addStandardDoc("competitions", newComp);
      await updateStandardDoc("competitions", compId, { compId });
      setLoading(false);
      history.push("/competitions");
    } catch (err) {
      console.log(err);
      window.alert(err.message);
      setLoading(false);
    }
    setLoading(false);
  };

  console.log(comp);

  return (
    <div>
      <h1>Create Competition</h1>
      <form>
        <TextField
          {...textFieldProps}
          label="Title"
          name="title"
          onChange={handleText}
          required
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Select
              {...selectFieldProps}
              options={compTypes}
              onChange={handleSelect}
              name="type"
              placeholder="Select Competition Type"
            />
            <Select
              {...selectFieldProps}
              options={ruleTypes}
              onChange={handleRuleTypeSelect}
              name="rule_type"
              placeholder="Select Rule Type"
            />
            <Select
              {...selectFieldProps}
              options={compFormats}
              onChange={handleLogTypeSelect}
              name="log_type"
              placeholder="Select Catch Log Format"
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                label="Start Date & Time"
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy HH:mm"
                margin="normal"
                id="date-picker-inline"
                value={comp.starts}
                onChange={(date) =>
                  setComp({
                    ...comp,
                    starts: date,
                  })
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            &nbsp;
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                label="End Date & Time"
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy HH:mm"
                margin="normal"
                id="date-picker-inline"
                value={comp.ends}
                onChange={(date) =>
                  setComp({
                    ...comp,
                    ends: date,
                  })
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <Card>
              <CardHeader title="Comp Logo" />

              <CardContent>
                {comp.logo_url && <img src={comp.logo_url} width={250} />}
                <FileUploader sendFile={getLogo} fileType="Logo Image" />
              </CardContent>
            </Card>
            <br />
            <Card>
              <CardHeader title="Map" />
              <CardContent>
                <TextField
                  {...textFieldProps}
                  label="Paste Google Map LatLng"
                  name="gmapLatLng"
                  onChange={handleGoogleMapLatLng}
                />

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      {...textFieldProps}
                      name="latitude"
                      disabled
                      value={coords.lat}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      {...textFieldProps}
                      name="longitude"
                      disabled
                      value={coords.long}
                    />
                  </Grid>
                </Grid>
                <Typography>Zoom: {comp.zoom}</Typography>
                {comp.latitude && comp.longitude && (
                  <VenueMapPreview
                    lat={coords.lat}
                    lng={coords.long}
                    viewPortUpdate={handleViewPortChange}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              {...selectFieldProps}
              options={venues}
              onChange={handleVenueSelect}
              name="venue"
              placeholder="Select Venue"
            />
            <Select
              {...selectFieldProps}
              options={users}
              onChange={handleOwnerSelect}
              name="owner"
              placeholder="Select Owner ..."
            />
            <TextField
              {...textFieldProps}
              label="Description"
              name="description"
              onChange={handleText}
              required
            />
            <TextField
              {...textFieldProps}
              label="Link to Rules"
              type="url"
              name="rules_url"
              onChange={handleText}
              required
            />
            <TextField
              {...textFieldProps}
              label="Link to Media"
              type="url"
              name="media_link"
              onChange={handleText}
              required
            />
            <Card>
              <CardHeader title="Comp Background" />

              <CardContent>
                {comp.background_url && (
                  <CardMedia
                    image={comp.background_url}
                    style={{ height: 250 }}
                  />
                )}
                <FileUploader
                  sendFile={getBackground}
                  fileType="Background Image"
                />
              </CardContent>
            </Card>
            <br />
            <Button
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              type="submit"
              onClick={handleSubmit}
              disabled={
                !comp.title ||
                !comp.type ||
                !comp.rule_type ||
                !comp.log_type ||
                !comp.starts ||
                !comp.ends ||
                !comp.venue ||
                !comp.description ||
                !comp.logo_url ||
                !comp.background_url ||
                !comp.latitude ||
                !comp.longitude ||
                !comp.owner ||
                !comp.ownerRef ||
                !comp.venue_id ||
                loading
              }
            >
              Create Competition
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
