import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { baitTypes, textFieldProps, species } from "../../../shared/constants";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardMedia,
  Grid,
  TextField,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Container,
} from "@material-ui/core";
import Select from "react-select";
import FileUploader from "../../../shared/FileUploader";
import { parseLatLong } from "../../maps/mapsHelpers";
import VenueMapPreview from "../../maps/VenueMapPreview";
import { Alert } from "@material-ui/lab";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function AddCatch() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {
    addStandardDoc,
    updateStandardDoc,
    GetCollection,
    getFirestoreGeoPoint,
  } = useDb();
  const baits = GetCollection("baits");
  const users = GetCollection("users");
  const venues = GetCollection("venues");
  const [coords, setCoords] = useState({
    lat: 0,
    long: 0,
  });

  const [data, setData] = useState({
    notentered: true,
    exact_location: true,
    weight_unit: "kg",
    timestamp: new Date(),
  });

  const handleText = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (v, e) => {
    let value;
    if (Array.isArray(v)) {
      value = v.map((item) => item.id);
    } else value = v.value;
    setData({
      ...data,
      [e.name]: value,
    });
  };

  const handleVenueSelect = (v) => {
    setData({
      ...data,
      venue_id: v.value,
      venue: v.label,
    });
  };

  const handleAnglerSelect = (v) => {
    setData({
      ...data,
      uid: v.value,
      userRef: v.ref,
      display_name: v.display_name || `${v.first_name} ${v.last_name}` || " ",
    });
  };

  const getFile = (fileObj) => {
    setData({
      ...data,
      photo_url: fileObj.url,
      photo_blurhash: fileObj.blurHash,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const location = getFirestoreGeoPoint(coords.lat, coords.long);

    setLoading(true);
    try {
      await addStandardDoc("catches", {
        ...data,
        weight: parseFloat(data.weight),
        location,
      });
      history.push("/all-catches");
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  const handleGoogleMapLatLng = (e) => {
    try {
      const coordinates = parseLatLong(e.target.value);
      setCoords(coordinates);
      setData({
        ...data,
        latitude: coordinates.lat,
        longitude: coordinates.long,
      });
    } catch (err) {
      window.alert("Invalid coordinates");
    }
  };

  const handleViewPortChange = (newViewPort) => {
    console.log(newViewPort);
    setData({
      ...data,
      latitude: newViewPort.latitude.toString(),
      longitude: newViewPort.longitude.toString(),
      zoom: newViewPort.zoom,
    });
    setCoords({
      lat: newViewPort.latitude,
      long: newViewPort.longitude,
    });
  };

  return (
    <div>
      <h1>Add Catch</h1>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Select
              options={users}
              onChange={handleAnglerSelect}
              name="angler"
              placeholder="Select Angler ..."
              className="carp-select"
            />
            <Select
              options={species}
              onChange={handleSelect}
              name="species"
              placeholder="Select Species ..."
              className="carp-select"
            />
            <Select
              options={venues}
              onChange={handleVenueSelect}
              name="venue"
              placeholder="Select Venue ..."
              className="carp-select"
            />
            <Select
              options={baits}
              onChange={handleSelect}
              name="baits_used"
              placeholder="Select Baits ..."
              className="carp-select"
              isMulti
            />
            <TextField
              {...textFieldProps}
              label="Weight (kg)"
              name="weight"
              onChange={handleText}
              required
            />
            {data.weight && (
              <Alert severity="info">
                Pounds: {(data.weight * 2.20462).toFixed(2)} lbs
              </Alert>
            )}
            <br />
            <Card>
              <CardHeader title="Map" />
              <CardContent>
                <TextField
                  {...textFieldProps}
                  label="Paste Google Map LatLng"
                  name="gmapLatLng"
                  onChange={handleGoogleMapLatLng}
                />

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      {...textFieldProps}
                      name="latitude"
                      disabled
                      value={coords.lat}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      {...textFieldProps}
                      name="longitude"
                      disabled
                      value={coords.long}
                    />
                  </Grid>
                </Grid>
                <Typography>Zoom: {data.zoom}</Typography>
                {data.latitude && data.longitude && (
                  <VenueMapPreview
                    lat={coords.lat}
                    lng={coords.long}
                    viewPortUpdate={handleViewPortChange}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                label="Catch Date & Time"
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy HH:mm"
                margin="normal"
                id="date-picker-inline"
                value={data.timestamp}
                onChange={(date) =>
                  setData({
                    ...data,
                    timestamp: date,
                  })
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <Card>
              <CardHeader title="Carp Image" />
              {data.img_url && (
                <CardMedia image={data.img_url} style={{ height: 250 }} />
              )}
              <CardContent>
                <FileUploader sendFile={getFile} fileType="Carp Image" />
              </CardContent>
            </Card>
            <br />
            <Container>
              <Button
                size="large"
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  !data.latitude ||
                  !data.longitude ||
                  !data.uid ||
                  !data.venue_id ||
                  !data.photo_url ||
                  loading
                }
              >
                Submit
              </Button>
            </Container>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
