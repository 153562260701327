import { TextField, Typography, Button, FormControl, Radio, FormLabel, RadioGroup, FormControlLabel } from '@material-ui/core'
import React, { useState } from 'react'
import FileUploader from '../../../shared/FileUploader'
import Select from 'react-select'
import { selectFieldProps, textFieldProps } from '../../../shared/constants'
import { useDb } from '../../../contexts/DatabaseContext'
import { useHistory } from 'react-router-dom'

export default function MarshallCatchLog() {
    const { GetTeams, marshallCatch } = useDb()
    const teams = GetTeams()
    const history = useHistory()
    
    const species = [
        {value: 'Common', label: 'Common'},
        {value: 'Mirror', label: 'Mirror'},
        {value: 'Grass', label: 'Grass'}
    ]

    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    })

    const getPhoto = (f) => {
        setState({
            ...state,
            data: {
                ...state.data,
                photo_url: f.url
            }
        })
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        setState({
            ...state,
            loading: true,
            error: null,
        })
        try {
            await marshallCatch(state.data)
            setState({
                loading: false,
                error: null,
                data: {}
            })
            history.push("/")
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message,
            })
        }
    }

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSelect = (v, e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v.value
            }
        })
    }

    return (
        <div>
            <h1>Marshall Catch Log</h1>
            <form onSubmit={handleSubmit}>
                <FileUploader sendFile={getPhoto} fileType={'Photo'} />
                {
                    state.data.photo_url && <div>
                        <TextField
                            {...textFieldProps}
                            label="Fish Weight (kg)"
                            required
                            name="weight"
                            type="number"
                            inputProps={{step: "0.01", lang:"en-US"}}
                            onChange={handleChange}
                        />
                        <Select
                            options={species}
                            placeholder="Select Species ..."
                            {...selectFieldProps}
                            onChange={handleSelect}
                            name="species"
                            className="carp-select"
                        />
                        <Select
                            options={teams}
                            placeholder="Select Team ..."
                            {...selectFieldProps}
                            onChange={handleSelect}
                            name="team"
                            className="carp-select"
                        />
                        {
                            state.data.team
                            ?
                                state.data.team.third_ref
                                ?
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Team Member Who Caught Fish</FormLabel>
                                    <RadioGroup aria-label="member" name="member" onChange={handleChange}>
                                        <FormControlLabel value="captain" control={<Radio />} label="Captain" />
                                        <FormControlLabel value="vice" control={<Radio />} label="Vice Captain" />
                                        <FormControlLabel value="third" control={<Radio />} label="Third Angler" />
                                    </RadioGroup>
                                </FormControl>
                                :
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Team Member Who Caught Fish</FormLabel>
                                    <RadioGroup aria-label="member" name="member" onChange={handleChange}>
                                        <FormControlLabel value="captain" control={<Radio />} label="Captain" />
                                        <FormControlLabel value="vice" control={<Radio />} label="Vice Captain" />
                                    </RadioGroup>
                                </FormControl>
                            :
                            null
                        }
                    </div>
                }
                
                { state.error && <Typography color="secondary">{state.error}</Typography>}
                <br/>
                {
                    state.compEntry
                    ?
                    <Button
                        type="submit"
                        color="primary"
                        size="large"
                        variant="contained"
                        fullWidth
                        disabled={
                            state.loading ||
                            state.weight < 10 ||
                            !state.videoUrl ||
                            !state.data.photo_url ||
                            !state.data.species
                        }
                    >
                        Enter
                    </Button>
                    :
                    <Button
                        type="submit"
                        color="primary"
                        size="large"
                        variant="contained"
                        fullWidth
                        disabled={
                            state.loading ||
                            !state.data.photo_url ||
                            !state.data.species
                        }
                    >
                        Submit
                    </Button>
                }
            </form>
        </div>
    )
}
