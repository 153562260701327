import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import CarpDataGrid from "../../../shared/datagrid/CarpDataGrid";

export default function AppUsers() {
  const { GetAppUsers, togglePro } = useDb();
  const users = GetAppUsers();
  const [state, setState] = useState({
    loading: false,
  });

  const handlePromote = async (uid) => {
    setState({
      loading: true,
    });
    try {
      await togglePro(uid, true);
      setState({
        loading: false,
      });
    } catch (err) {
      setState({
        loading: false,
      });
      console.log(err.message);
    }
  };

  const handleDemote = async (uid) => {
    setState({
      loading: true,
    });
    try {
      await togglePro(uid, false);
      setState({
        loading: false,
      });
    } catch (err) {
      setState({
        loading: false,
      });
      console.log(err.message);
    }
  };

  const rows =
    users &&
    users.map((u) => ({
      id: u.id,
      pro: u.pro ? "YES" : "NO",
      displayName: u.display_name,
      email: u.email,
    }));

  const columns = [
    { field: "email", headerName: "Email:", width: 400 },
    { field: "pro", headerName: "PRO", width: 200 },
    { field: "displayName", headerName: "Display Name", width: 200 },
    { field: "id", headerName: "ID", width: 300 },
    { field: "team_id", headerName: "Team", width: 300 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params.row.pro === "YES" ? (
              <Button
                disabled={state.loading}
                color="secondary"
                onClick={() => handleDemote(params.row.id)}
              >
                Disable Pro
              </Button>
            ) : (
              <Button
                disabled={state.loading}
                variant="contained"
                color="primary"
                onClick={() => handlePromote(params.row.id)}
              >
                Activate Pro
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <h1>App Users</h1>
      <CarpDataGrid data={rows} columns={columns} />
    </div>
  );
}
