import React from "react";
import BaitList from "./BaitList";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

export default function Baits() {
  return (
    <div>
      <h1>Baits</h1>
      <Link to="/add-bait">
        <Button color="primary" variant="contained" startIcon={<Add />}>
          Add Bait
        </Button>
      </Link>
      <div style={{ marginTop: 10 }}>
        <BaitList />
      </div>
    </div>
  );
}
