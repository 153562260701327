import React from "react";
import CompetitionList from "./CompetitionList";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function Competitions() {
  return (
    <div>
      <h1>Competitions</h1>
      <Link to="/create-competition">
        <Button variant="contained" color="primary">
          Create Competition
        </Button>
      </Link>
      <br />
      <br />
      <CompetitionList />
    </div>
  );
}
