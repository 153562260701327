import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import FishList from './FishList'

export default function ApprovedCatches() {
    const { GetApprovedCatches } = useDb()
    const fish = GetApprovedCatches()
    return (
        <div>
            <h1>Approved Catches</h1>
            <FishList
                data={fish}
            />
        </div>
    )
}
