import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import FishList from './FishList'

export default function IncomingCatches() {
    const { GetIncomingCatches } = useDb()
    const fish = GetIncomingCatches()
    
    return (
        <div>
            <h1>Incoming Catches</h1>
            <FishList
                data={fish}
            />
        </div>
    )
}
