import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDb } from "../../contexts/DatabaseContext";
import VenueMap from "./VenueMap";

export default function VenueMapContainer() {
  const { venueId, latitude, longitude } = useParams();
  const { getVenueMapDataAsync } = useDb();
  const [speciesFilter, setSpeciesFilter] = useState([]);

  const [geoJson, setGeoJson] = useState({
    type: "FeatureCollection",
    features: [],
  });

  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let species = params.getAll("species");
  let days = params.get("days");
  console.log(days);
  let weight = params.get("weight");

  console.log(days);
  console.log(weight);

  const updateMapFilters = async () => {
    console.log(species);
    const floatDays = parseInt(days);
    const points = await getVenueMapDataAsync(
      venueId,
      species,
      weight,
      floatDays
    );

    const geoFeatures =
      points &&
      points.map((point) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [point.coordinates._long, point.coordinates._lat],
        },
      }));

    setGeoJson({
      ...geoJson,
      features: geoFeatures,
    });
  };

  useEffect(() => {
    return updateMapFilters();
  }, []);

  console.log(geoJson);

  return (
    <div>
      {geoJson && (
        <VenueMap latitude={latitude} longitude={longitude} geoJson={geoJson} />
      )}
    </div>
  );
}
