import { Button, Card, CardActions, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { CloudDownload, Videocam } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext';
import { textFieldProps } from '../../../shared/constants';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        background: theme.palette.primary.main
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
}))

export default function CatchDetail({ fish, open, close }) {
    const classes = useStyles()
    const { getCatchVids, approveCatch, declineCatch } = useDb()
    const { id, species, date, weight, photoUrl, uid, venue, angler } = fish;
    const [vids, setVids] = useState()
    const [declineReason, setDeclineReason] = useState()
    const [state, setState] = useState({
        error: null,
        loading: false,
    })

    useEffect(async () => {
        const v = await getCatchVids(id)
        setVids(v)
        console.log(v)
        return v
    }, [id])

    const handleClose = () => {
        setState({
            error: null,
            loading: false
        })
        close()
    }

    const handleApprove = async () => {
        setState({
            loading: true,
            error: null
        })
        try {
            await approveCatch(id)
            handleClose()
        }
        catch(err) {
            setState({
                loading: false,
                error: err.message
            })
        }
    }

    const handleDecline = async () => {
        setState({
            loading: true,
            error: null
        })
        try {
            await declineCatch(id, declineReason)
            handleClose()
        }
        catch(err) {
            setState({
                loading: false,
                error: err.message
            })
        }
    }

    console.log(fish)

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle className={classes.dialogTitle}>
                {species} - {weight} 
            </DialogTitle>
            <DialogContent>
                <Typography>Angler: {angler}</Typography>
                <Typography>Caught: {date}</Typography>
                Note: Please wait for video to load. It takes a minute.
                <Grid container spacing={3}>
                
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardMedia
                                component="image"
                                image={photoUrl}
                                className={classes.media}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {
                            vids && vids.map(v => (
                                <Card key={v.id}>
                                    <CardMedia
                                        component="video"
                                        image={v.video_url}
                                        autoPlay
                                    />
                                    <CardActions>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            startIcon={<Videocam />}
                                            href={v.video_url}
                                            target="_blank"
                                            download
                                        >
                                            View Video
                                        </Button>
                                    </CardActions>

                                </Card>
                            ))
                        }
                    </Grid>
                    
                </Grid>
                <TextField
                    {...textFieldProps}
                    label="Decline Reason"
                    onChange={e => setDeclineReason(e.target.value)}
                />
                {
                    state.error && <Typography color="secondary">{state.error}</Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<CloudDownload />}
                    href={photoUrl}
                    target="_blank"
                >
                    Download Photo
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={state.loading}
                    onClick={handleApprove}
                >
                    Approve
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    disabled={state.loading || !declineReason || fish.approved}
                    onClick={handleDecline}
                >
                    Decline
                </Button>
                <Button
                    onClick={handleClose}
                    disabled={state.loading}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}
