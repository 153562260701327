import { Button, FormControlLabel, Switch, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { selectFieldProps, textFieldProps } from '../../../shared/constants'
import FileUploader from '../../../shared/FileUploader'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'

export default function LogCatch() {
    const { submitCatch } = useDb()
    const history = useHistory()
    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {},
        compEntry: false,
        videoUrl: null
    })

    const species = [
        {value: 'Common', label: 'Common'},
        {value: 'Mirror', label: 'Mirror'},
        {value: 'Grass', label: 'Grass'}

    ]

    const getPhoto = (f) => {
        setState({
            ...state,
            data: {
                ...state.data,
                photo_url: f.url
            }
        })
    }

    const handleSelect = (v, e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v.value
            }
        })
    }

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log('Submitting')
        setState({
            ...state,
            loading: true,
            error: null,
        })
        try {
            await submitCatch({ ...state.data, compEntry: state.compEntry })
            setState({
                data: {},
                loading: false,
                error: null,
            })
            history.push('/')
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <div>
            <h1>Log a Catch</h1>
            <form onSubmit={handleSubmit}>
                <FileUploader sendFile={getPhoto} fileType={'Photo'} />
                <TextField
                    {...textFieldProps}
                    label="Venue/Place/Dam Name"
                    required
                    name="venue"
                    onChange={handleChange}
                />
                <TextField
                    {...textFieldProps}
                    label="Fish Weight (kg)"
                    required
                    name="weight"
                    type="number"
                    inputProps={{step: "0.01", lang:"en-US"}}
                    onChange={handleChange}
                />
                <Select
                    options={species}
                    placeholder="Select Species"
                    {...selectFieldProps}
                    onChange={handleSelect}
                    name="species"
                    className="carp-select"
                />
                { state.error && <Typography color="secondary">{state.error}</Typography>}
                <br/>
                {
                    state.compEntry
                    ?
                    <Button
                        type="submit"
                        color="primary"
                        size="large"
                        variant="contained"
                        fullWidth
                        disabled={
                            state.loading ||
                            state.weight < 10 ||
                            !state.videoUrl ||
                            !state.data.photo_url ||
                            !state.data.species
                        }
                    >
                        Enter
                    </Button>
                    :
                    <Button
                        type="submit"
                        color="primary"
                        size="large"
                        variant="contained"
                        fullWidth
                        disabled={
                            state.loading ||
                            !state.data.photo_url ||
                            !state.data.species
                        }
                    >
                        Submit
                    </Button>
                }
            </form>
        </div>
    )
}
