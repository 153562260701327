import { AppBar, Avatar, Button, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Toolbar, Typography } from '@material-ui/core'
import { AddBox, ChevronLeft, ChevronRight, FormatListNumbered, History, Home, Menu } from '@material-ui/icons';
import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import clsx from 'clsx';
import CustomerRoutes from '../../routes/CustomerRoutes';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    icons: {
        color: '#fff',
        marginLeft: theme.spacing(2),
    },
    menuText: {
        color: '#fff',
        textDecoration: 'none',
    },
    subMenuItem: {
        paddingLeft: 55
    },
    root: {
        display: 'flex',
    },
    appBar: {
        backgroundColor: theme.palette.primary.main,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        background: theme.palette.primary.main
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    greeting: {
        paddingRight: 10
    }
}));

export default function CustomerPage() {
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const { logOut, currentUser } = useAuth()

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLogOut = async () => {
        try {
            await logOut()
        }
        catch(err) {
            console.log(err.message)
        }
    }

    const menuItems = [
        {
            label: 'Home',
            link: '/',
            icon: <Home />
        },
        {
            label: 'Log A Catch',
            link: '/log-catch',
            icon: <AddBox />
        },
        {
            label: 'Your Catches',
            link: '/catch-history',
            icon: <History />
        },
        {
            label: 'Leaderboard',
            link: '/teams-board',
            icon: <FormatListNumbered />
        },
    ]

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <Menu />
                    </IconButton>
                    <Typography variant="h6" noWrap  style={{flex:1}}>
                        PC Wild Card
                    </Typography>
                    <div className={classes.greeting}>
                        {currentUser.display_name &&  !open ? `Hello, ${currentUser.display_name}` : null}
                    </div>
                    <Avatar alt="User Image" src={currentUser.photo_url} className={classes.large} />
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <div style={{"width": "100%", "padding": "10px 10px 0 0"}}>
                          
                    </div>
                        <IconButton style={{"color":"#fff"}} onClick={handleDrawerClose}>
                            <ChevronLeft /> 
                        </IconButton>
                </div>
                <Divider />
                <List>
                    {menuItems.map((item, index) => (
                        <Link key={index} to={item.link}>
                            <ListItem button >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.label} />
                            </ListItem>
                        </Link>
                    ))}
                </List>
                <Button
                    color="secondary"
                    fullWidth
                    className={classes.btn}
                    size="large"
                    onClick={handleLogOut}
                >
                    Log Out
                </Button>
            </Drawer>
            <main className={clsx(classes.content, {
                [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                <CustomerRoutes />
            </main>
        </div>
    )
}
