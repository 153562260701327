import React from "react";
import { useDb } from "../../contexts/DatabaseContext";
import { Avatar } from "@material-ui/core";
import BackLink from "../../shared/BackLink";
import CarpDataGrid from "../../shared/datagrid/CarpDataGrid";

export default function VenueScrapes() {
  const { GetCollection } = useDb();
  const venueScrapes = GetCollection("venue_scrapes");

  const rows =
    venueScrapes &&
    venueScrapes.map((v) => ({
      ...v,
    }));

  const columns = [
    {
      field: "imgUrl",
      headerName: " ",
      width: 120,
      renderCell: (params) => {
        return <Avatar src={params.row.imgUrl} />;
      },
    },
    {
      field: "title",
      headerName: "Title",
      width: 200,
    },
    {
      field: "address",
      headerName: "Address",
      width: 700,
    },
  ];

  return (
    <div>
      <h1>Venue Scrapes</h1>
      <BackLink />
      {rows && <CarpDataGrid data={rows} columns={columns} />}
    </div>
  );
}
