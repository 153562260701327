import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import SingleCatch from './SingleCatch'

export default function CatchHistory() {
    const { GetMyCatches } = useDb()
    const fish = GetMyCatches()
    return (
        <div>
            <h1>My Catches</h1>
            {
                fish && fish.map(f => (
                    <SingleCatch key={f.id} fish={f} />
                ))
            }
        </div>
    )
}
