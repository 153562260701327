export const heatMapLayer = {
  id: "heatmapLayer",
  type: "heatmap",
  source: "points",
  maxzoom: 15,
  paint: {
    // Increase the heatmap weight based on frequency and property magnitude
    "heatmap-weight": 1,
    // Increase the heatmap color weight by zoom level
    "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 15, 3],
    // Color ramp for heatmap.
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0,
      "rgba(33,102,172,0)",
      0.2,
      "rgb(103,169,207)",
      0.4,
      "rgb(209,229,240)",
      0.6,
      "rgb(253,219,199)",
      0.8,
      "rgb(239,138,98)",
      1,
      "rgb(178,24,43)",
    ],
    // Adjust the heatmap radius by zoom level
    "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 12, 15, 24],
    // Transition from heatmap to circle layer by zoom level
    "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0.75, 15, 0],
  },
};
