import { Avatar, Card, CardHeader } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'

export default function AnglerEntry({ entry, pos }) {

    return (
        <div>
            <Link to={`/angler-entries/${entry.id}`}>
                <Card style={{ marginBottom: 10 }}>
                    <CardHeader 
                        title={entry.angler}
                        avatar={
                            <Avatar>
                                {pos}
                            </Avatar>
                        }
                        subheader={`Bag Weight: ${entry.combinedWeight} kg`}
                    />
                </Card>
            </Link>
        </div>
    )
}
