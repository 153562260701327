import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { Avatar, Button } from "@material-ui/core";
import CarpDataGrid from "../../../shared/datagrid/CarpDataGrid";
import { Link } from "react-router-dom";
import { Warning } from "@material-ui/icons";

export default function BaitList() {
  const { GetCollection, deleteStandardDoc } = useDb();
  const baits = GetCollection("baits");

  const handleDelete = async (baitId) => {
    const confirm = window.confirm("Are you sure? This cannot be undone.");
    if (confirm) {
      await deleteStandardDoc("baits", baitId);
    }
  };

  const rows =
    baits &&
    baits.map((b) => ({
      ...b,
    }));

  const columns = [
    {
      field: "img_url",
      headerName: " ",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return <Avatar src={params.row.img_url} />;
      },
    },
    {
      field: "title",
      headerName: "Title",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Link to={`/edit-bait/${params.row.id}`}>
              <Button variant="outlined" color="primary">
                {params.row.title}
              </Button>
            </Link>
          </div>
        );
      },
    },
    { field: "type", headerName: "Type", width: 300 },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 300,
    //   renderCell: (params) => {
    //     return (
    //       <Button
    //         startIcon={<Warning />}
    //         color="error"
    //         variant="outlined"
    //         onClick={() => handleDelete(params.row.id)}
    //       >
    //         Delete Bait
    //       </Button>
    //     );
    //   },
    // },
  ];

  return <div>{baits && <CarpDataGrid data={rows} columns={columns} />}</div>;
}
