import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import CarpDataGrid from "../../../shared/datagrid/CarpDataGrid";
import { PersonAdd } from "@material-ui/icons";

export default function AddAnglerToTeam({ teamId, open, close, position }) {
  const { GetCollection, updateStandardDoc } = useDb();
  const [loading, setLoading] = useState(false);
  const users = GetCollection("users");
  const handleClose = () => {
    close();
  };

  const { idName, refName } = position;

  const handleAdd = async (angler) => {
    setLoading(true);
    // Add the ref and id to the team doc
    await updateStandardDoc("teams", teamId, {
      [idName]: angler.ref.id,
      [refName]: angler.ref,
    });

    // Add the team ID to the user doc
    await updateStandardDoc("users", angler.ref.id, {
      team_id: teamId,
    });
    setLoading(false);

    handleClose();
  };

  const rows =
    users &&
    users.map((user) => ({
      ...user,
    }));

  const columns = [
    {
      field: "photo_url",
      headerName: ".",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Avatar src={params.row.photo_url} />
          </div>
        );
      },
    },
    { field: "email", headerName: "Email", width: 200 },
    { field: "display_name", headerName: "Name", width: 200 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 280,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Button
              startIcon={<PersonAdd />}
              variant="contained"
              color="primary"
              onClick={() => handleAdd(params.row)}
            >
              Add
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="lg" fullWidth>
      <DialogTitle>Add Member to Team</DialogTitle>
      <DialogContent>
        {rows && <CarpDataGrid data={rows} columns={columns} />}
      </DialogContent>
    </Dialog>
  );
}
