import React from "react";
import CarpDataGrid from "../../../shared/datagrid/CarpDataGrid";
import { Card, CardContent, IconButton } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Link } from "react-router-dom";

export default function CompCatchList({ catches }) {
  const data = catches.map((c) => ({
    ...c,
    catchDate: c.timestamp.toDate(),
  }));

  const columns = [
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Link to={`/comp-catch-detail/${params.row.id}`}>
              <IconButton>
                <Search />
              </IconButton>
            </Link>
          </div>
        );
      },
    },
    { field: "display_name", width: 200, headerName: "Angler" },
    { field: "species", width: 200, headerName: "Species" },
    { field: "weight", width: 200, headerName: "Weight" },
    { field: "catchDate", width: 200, headerName: "Catch Date" },
  ];
  return (
    <Card>
      <CardContent>
        <CarpDataGrid data={data} columns={columns} />
      </CardContent>
    </Card>
  );
}
