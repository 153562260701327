import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { Avatar, Button } from "@material-ui/core";
import CarpDataGrid from "../../../shared/datagrid/CarpDataGrid";
import { Link } from "react-router-dom";

export default function ArticleList() {
  const { GetCollection } = useDb();
  const articles = GetCollection("articles");

  const rows =
    articles &&
    articles.map((a) => ({
      ...a,
    }));

  const columns = [
    {
      field: "img_url",
      headerName: "Image",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return <Avatar src={params.row.img_url} />;
      },
    },
    {
      field: "title",
      headerName: "Title",
      sortable: false,
      width: 600,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Link to={`/article/${params.row.id}`}>
              <Button variant="outlined" color="primary">
                {params.row.title}
              </Button>
            </Link>
          </div>
        );
      },
    },
    { field: "venue_title", headerName: "Venue", width: 300 },
  ];

  return (
    <div>{articles && <CarpDataGrid data={rows} columns={columns} />}</div>
  );
}
