import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { textFieldProps, regions, countries } from "../../shared/constants";
import Select from "react-select";
import { parseLatLong } from "../maps/mapsHelpers";
import FileUploader from "../../shared/FileUploader";
import VenueMapPreview from "../maps/VenueMapPreview";
import { useDb } from "../../contexts/DatabaseContext";
import { useHistory } from "react-router-dom";
import { geohashForLocation } from "geofire-common";

export default function AddVenue() {
  const [coords, setCoords] = useState({
    lat: 0,
    long: 0,
  });
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { addStandardDoc, getFirestoreGeoPoint, updateStandardDoc } = useDb();
  const history = useHistory();

  const handleGoogleMapLatLng = (e) => {
    try {
      const coordinates = parseLatLong(e.target.value);
      setCoords(coordinates);
      setData({
        ...data,
        latitude: coordinates.lat,
        longitude: coordinates.long,
      });
    } catch (err) {
      window.alert("Invalid coordinates");
    }
  };

  const getFile = (fileObj) => {
    setData({
      ...data,
      img_url: fileObj.url,
      img_blurash: fileObj.blurHash,
    });
  };

  const handleViewPortChange = (newViewPort) => {
    console.log(newViewPort);
    setData({
      ...data,
      latitude: newViewPort.latitude.toString(),
      longitude: newViewPort.longitude.toString(),
      zoom: newViewPort.zoom,
    });
    setCoords({
      lat: newViewPort.latitude,
      long: newViewPort.longitude,
    });
  };

  const handleText = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (v, e) => {
    setData({
      ...data,
      [e.name]: v.label,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const location = getFirestoreGeoPoint(coords.lat, coords.long);
      // Compute geohash
      const geohash = geohashForLocation([coords.lat, coords.long]);
      const venue_id = await addStandardDoc("venues", {
        ...data,
        location,
        geohash,
      });
      await updateStandardDoc("venues", venue_id, { venue_id });
      history.push("/venues");
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  return (
    <div style={{ marginBottom: 50 }}>
      <h1>Add Venue</h1>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Venue Details" />
              {data.img_url && (
                <CardMedia image={data.img_url} style={{ height: 250 }} />
              )}
              <CardContent>
                <FileUploader sendFile={getFile} fileType="Venue Image" />
              </CardContent>
            </Card>
            <TextField
              {...textFieldProps}
              label="Title"
              name="title"
              onChange={handleText}
              required
            />
            <Select
              options={regions}
              placeholder="Select Region ..."
              className="carp-select"
              name="region"
              onChange={handleSelect}
            />
            <Select
              options={countries}
              placeholder="Select Country ..."
              className="carp-select"
              name="country"
              onChange={handleSelect}
            />
            <TextField
              {...textFieldProps}
              label="State / Province"
              name="state"
              onChange={handleText}
              required
            />
            <TextField
              {...textFieldProps}
              label="City/Town"
              name="city"
              onChange={handleText}
              required
            />
            <TextField
              {...textFieldProps}
              label="Establishment Name"
              name="contact"
              onChange={handleText}
            />
            <TextField
              {...textFieldProps}
              label="Contact Number"
              name="contact"
              onChange={handleText}
            />
            <TextField
              {...textFieldProps}
              label="Gate Address (Get from Google Maps)"
              name="gate_address"
              onChange={handleText}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Map" />
              <CardContent>
                <TextField
                  {...textFieldProps}
                  label="Paste Google Map LatLng"
                  name="gmapLatLng"
                  onChange={handleGoogleMapLatLng}
                />

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      {...textFieldProps}
                      name="latitude"
                      disabled
                      value={coords.lat}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      {...textFieldProps}
                      name="longitude"
                      disabled
                      value={coords.long}
                    />
                  </Grid>
                </Grid>
                <Typography>Zoom: {data.zoom}</Typography>
                {data.latitude && data.longitude && (
                  <VenueMapPreview
                    lat={coords.lat}
                    lng={coords.long}
                    viewPortUpdate={handleViewPortChange}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Container>
          <Button
            size="large"
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              !data.latitude ||
              !data.longitude ||
              !data.title ||
              !data.region ||
              !data.country ||
              !data.state ||
              !data.city ||
              !data.zoom ||
              !data.img_url ||
              loading
            }
          >
            Submit
          </Button>
        </Container>
      </form>
    </div>
  );
}
