import {
  AppBar,
  Avatar,
  Button,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import AdminRoutes from "../../routes/AdminRoutes";
import { useAuth } from "../../contexts/AuthContext";
import {
  ChevronLeft,
  Fastfood,
  FormatListNumbered,
  GroupWork,
  Inbox,
  Menu,
  NewReleasesSharp,
  People,
  PhotoCamera,
  Rowing,
  ThumbDown,
  ThumbUpSharp,
  VerifiedUser,
  Waves,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import clsx from "clsx";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  toolbarTitle: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  greeting: {
    paddingRight: 10,
  },
}));

export default function AdminView() {
  const classes = useStyles();
  const { currentUser, logOut } = useAuth();
  const [open, setOpen] = useState(false);

  const handleLogOut = async () => {
    await logOut();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuItems = [
    {
      label: "Venues",
      link: "/venues",
      icon: <Waves />,
    },
    {
      label: "Venue Scrapes",
      link: "/venue-scrapes",
      icon: <Waves />,
    },
    {
      label: "Competitions",
      link: "/competitions",
      icon: <Waves />,
    },
    {
      label: "Baits",
      link: "/baits",
      icon: <Fastfood />,
    },
    {
      label: "Articles",
      link: "/articles",
      icon: <NewReleasesSharp />,
    },
    {
      label: "App Users",
      link: "/app-users",
      icon: <People />,
    },
    {
      label: "All Catches",
      link: "/all-catches",
      icon: <Rowing />,
    },
    {
      label: "Teams",
      link: "/teams",
      icon: <GroupWork />,
    },
  ];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.toolbarTitle}>
            Pro Carp Series - Dashboard
          </Typography>
          <div className={classes.greeting}>
            {currentUser.display_name && `Hello, ${currentUser.display_name}`}
          </div>
          <Avatar
            alt="Remy Sharp"
            src={currentUser.photo_url}
            className={classes.large}
          />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div style={{ width: "100%", padding: "10px 10px 0 0" }}></div>
          <IconButton style={{ color: "#fff" }} onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </div>

        <List>
          {menuItems.map((item, index) => (
            <Link key={index} to={item.link}>
              <ListItem button>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            </Link>
          ))}
        </List>
        <Button onClick={handleLogOut} color="secondary">
          Log Out
        </Button>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {/* <div className={classes.toolbar} /> */}
        <div className={classes.drawerHeader} />
        <AdminRoutes />
      </main>
    </div>
  );
}
