import { Avatar, Button, IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import CarpDataGrid from "../../../shared/datagrid/CarpDataGrid";
import AddPegZone from "./AddPegZone";
import CreateTeamDialog from "./CreateTeamDialog";
import { Link } from "react-router-dom";

export default function Teams() {
  const { GetTeams } = useDb();
  const teams = GetTeams();
  const [createOpen, setCreateOpen] = useState(false);
  const [assignOpen, setAssignOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState();

  const handlePegSelect = (teamId) => {
    setSelectedTeam(teamId);
    setAssignOpen(true);
  };

  const handePegDeselect = () => {
    setAssignOpen(false);
    setSelectedTeam(null);
  };

  const rows =
    teams &&
    teams.map((t) => ({
      id: t.id,
      name: t.name,
      peg: t.peg,
      zone: t.zone,
    }));

  const columns = [
    {
      field: "action",
      headerName: "Edit",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Link to={`/edit-team/${params.row.id}`}>
              <IconButton>
                <Edit />
              </IconButton>
            </Link>
          </div>
        );
      },
    },
    { field: "name", headerName: "Team Name", width: 300 },
  ];

  return (
    <div>
      <h1>Teams</h1>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setCreateOpen(true)}
      >
        Create Team
      </Button>
      <br />
      <br />
      <CarpDataGrid data={rows} columns={columns} />

      <CreateTeamDialog open={createOpen} close={() => setCreateOpen(false)} />

      {selectedTeam && (
        <AddPegZone
          teamId={selectedTeam}
          open={assignOpen}
          close={handePegDeselect}
        />
      )}
    </div>
  );
}
