import React from "react";
import { useDb } from "../../contexts/DatabaseContext";
import CarpDataGrid from "../../shared/datagrid/CarpDataGrid";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function VenueList() {
  const { GetCollection } = useDb();
  const venues = GetCollection("venues");
  const rows =
    venues &&
    venues.map((v) => ({
      ...v,
    }));

  const columns = [
    {
      field: "title",
      headerName: "Title",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Link to={`/venue-detail/${params.row.id}`}>
              <Button variant="outlined" color="primary">
                {params.row.title}
              </Button>
            </Link>
          </div>
        );
      },
    },
    { field: "region", headerName: "Region", width: 300 },
    { field: "country", headerName: "Country", width: 300 },
    { field: "state", headerName: "State", width: 300 },
    { field: "city", headerName: "City", width: 300 },
  ];
  return (
    <div style={{ marginTop: 10 }}>
      {venues && <CarpDataGrid data={rows} columns={columns} />}
    </div>
  );
}
