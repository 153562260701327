import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";

export default function TeamCheckCard({ teamId }) {
  const { getStandardDoc } = useDb();
  const [team, setTeam] = useState();
  const loadTeam = async () => {
    const t = await getStandardDoc("teams", teamId);
    setTeam(t);
  };

  useEffect(() => {
    return loadTeam();
  }, [teamId]);
  return !team ? <CircularProgress /> : <div>Team: {team.name}</div>;
}
