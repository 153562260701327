import React, { useEffect, useState } from "react";
import BackLink from "../../../shared/BackLink";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDb } from "../../../contexts/DatabaseContext";
import { Button, Grid, LinearProgress } from "@material-ui/core";
import CompParticipants from "./CompParticipants";
import AddAnglerToComp from "./AddAnglerToComp";
import CompCatches from "./CompCatches";
import { Link } from "react-router-dom";

export default function CompetitionDetail() {
  const { compId } = useParams();
  const { getStandardDoc, addAnglerToComp } = useDb();
  const [addOpen, setAddOpen] = useState(false);
  const [comp, setComp] = useState();
  const loadComp = async () => {
    const c = await getStandardDoc("competitions", compId);
    setComp(c);
  };

  useEffect(() => {
    return loadComp();
  }, [compId]);

  const handleAnglerAdd = async (anglerId) => {
    await addAnglerToComp(compId, anglerId);
    setAddOpen(false);
  };

  return !comp ? (
    <LinearProgress />
  ) : (
    <div>
      <BackLink />
      <h1>{comp.title}</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddOpen(true)}
          >
            Add Angler
          </Button>
          &nbsp;
          <Link to={`/team-entries/${compId}`}>
            <Button variant="contained" color="primary">
              Team Entries
            </Button>
          </Link>
          <CompParticipants
            participantIds={comp.participants}
            participantRefs={comp.participantRefs}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <CompCatches compId={compId} />
        </Grid>
      </Grid>

      <AddAnglerToComp
        open={addOpen}
        close={() => setAddOpen(false)}
        handleAnglerAdd={handleAnglerAdd}
      />
    </div>
  );
}
