import React, { useState } from "react";
import MapGL, { Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
// added the following 6 lines.
import mapboxgl from "mapbox-gl";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
mapboxgl.workerClass = MapboxWorker;

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

function generateRandomGeoJsonFeatures(numFeatures) {
  const features = [];

  const hartbeespoortDam = {
    latitude: -25.7123,
    longitude: 27.8259,
  };

  const maxOffset = 0.05; // Adjust this value to change the spread of the random points

  for (let i = 0; i < numFeatures; i++) {
    // Generate random offsets for latitude and longitude
    const offsetLat = (Math.random() - 0.5) * maxOffset * 2;
    const offsetLng = (Math.random() - 0.5) * maxOffset * 2;

    const latitude = hartbeespoortDam.latitude + offsetLat;
    const longitude = hartbeespoortDam.longitude + offsetLng;

    const feature = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Point",
        coordinates: [longitude, latitude],
      },
    };

    features.push(feature);
  }

  return {
    type: "FeatureCollection",
    features: features,
  };
}

// Generate 100 random features
const geoJsonTest = generateRandomGeoJsonFeatures(100);

const hartiesGeoTest = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [27.813512, -25.710627],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [27.808512, -25.707985],
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [27.804115, -25.704742],
      },
    },
  ],
};

const heatmapLayer = {
  id: "heatmap",
  type: "heatmap",
  paint: {
    "heatmap-weight": 1,
    "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 3],
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0,
      "rgba(33,102,172,0)",
      0.2,
      "rgb(103,169,207)",
      0.4,
      "rgb(209,229,240)",
      0.6,
      "rgb(253,219,199)",
      0.8,
      "rgb(239,138,98)",
      1,
      "rgb(178,24,43)",
    ],
    "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 2, 9, 20],
    "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 1, 9, 0],
  },
};

const alternateHeatmapLayer = {
  id: "heatmapLayer",
  type: "heatmap",
  source: "points",
  maxzoom: 15,
  paint: {
    // Increase the heatmap weight based on frequency and property magnitude
    "heatmap-weight": 1,
    // Increase the heatmap color weight by zoom level
    "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 15, 3],
    // Color ramp for heatmap.
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0,
      "rgba(33,102,172,0)",
      0.2,
      "rgb(103,169,207)",
      0.4,
      "rgb(209,229,240)",
      0.6,
      "rgb(253,219,199)",
      0.8,
      "rgb(239,138,98)",
      1,
      "rgb(178,24,43)",
    ],
    // Adjust the heatmap radius by zoom level
    "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 12, 15, 24],
    // Transition from heatmap to circle layer by zoom level
    "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0.75, 15, 0],
  },
};

const HeatMapTest = ({ geoJson }) => {
  const [viewport, setViewport] = useState({
    latitude: -25.7123,
    longitude: 27.8259,
    zoom: 12,
    bearing: 0,
    pitch: 0,
  });

  return (
    <div style={{ height: "100vh" }}>
      <MapGL
        {...viewport}
        width="100"
        height="100"
        mapStyle="mapbox://styles/iwanzbiz/cli0jy7ot02cd01prgdrx1ou6"
        onViewportChange={setViewport}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        onMove={(evt) => setViewport(evt.viewState)}
      >
        <Source type="geojson" data={geoJsonTest}>
          <Layer {...alternateHeatmapLayer} />
        </Source>
      </MapGL>
    </div>
  );
};

export default HeatMapTest;
