import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { selectFieldProps } from "../../../shared/constants";
import { useDb } from "../../../contexts/DatabaseContext";
import BackLink from "../../../shared/BackLink";
import { Snackbar } from "@material-ui/core";

export default function ArticleDetail() {
  const { articleId } = useParams();
  const { GetCollection, updateStandardDoc } = useDb();
  const venues = GetCollection("venues");
  const [snackOpen, setSnackOpen] = useState(false);

  const selectVenue = async (v) => {
    await updateStandardDoc("articles", articleId, {
      venue_id: v.id,
      venue_title: v.title,
    });
    setSnackOpen(true);
  };

  return (
    <div>
      <h1>Article Detail</h1>
      <BackLink />
      <Select
        {...selectFieldProps}
        options={venues}
        className="carp-select"
        onChange={selectVenue}
        placeholder="Select a venue"
      />

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackOpen}
        onClose={() => setSnackOpen(false)}
        message="Venue updated"
        autoHideDuration={3000}
      />
    </div>
  );
}
