import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps } from '../../../shared/constants'

export default function AddMarshallDialog({ open, close }) {
    const { GetAppUsers, makeMarshall } = useDb()
    const users = GetAppUsers()

    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    })
    
    const handleClose = () => {
        setState({
            loading: false,
            error: null,
            data: {}
        })
        close()
    }

    const handleSelect = (v) => {
        setState({
            ...state,
            data: {
                ...state.data,
                uid: v.value
            }
        })
    }

    const handleSubmit = async () => {
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            await makeMarshall(state.data.uid)
            handleClose()
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add Marshall</DialogTitle>
            <DialogContent>
                <Select
                    {...selectFieldProps}
                    placeholder="Select User ..."
                    options={users}
                    onChange={handleSelect}
                />
            </DialogContent>
            <DialogActions>
                {
                    state.error && <Typography color="secondary">{state.error}</Typography>
                }
                <Button
                    color="secondary"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}
