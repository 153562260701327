import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDb } from "../../contexts/DatabaseContext";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
} from "@material-ui/core";
import BackLink from "../../shared/BackLink";
import VenueCatches from "./VenueCatches";

export default function VenueDetail() {
  const { venueId } = useParams();
  const { getStandardDoc } = useDb();
  const [venue, setVenue] = useState();

  const loadVenue = async () => {
    const v = await getStandardDoc("venues", venueId);
    setVenue(v);
  };

  useEffect(() => {
    return loadVenue();
  }, [venueId]);

  if (!venue) {
    return <LinearProgress />;
  }

  return (
    <div>
      <h1>{venue.title}</h1>
      <BackLink />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Venue Details" />
            <CardContent>Editing Venue Details will go here</CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Venue Catches" />
            <CardContent>
              <VenueCatches venueId={venueId} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
