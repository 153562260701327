import React from "react";
import { Route } from "react-router-dom";
import Forgot from "../components/auth/Forgot";
import SignIn from "../components/auth/SignIn";
import SignUp from "../components/auth/SignUp";
import AnglerEntries from "../components/leaderboards/angler-of-year/AnglerEntries";
import AnglerOfTheYear from "../components/leaderboards/angler-of-year/AnglerOfTheYear";
import BiggestCommon from "../components/leaderboards/biggest-common/BiggestCommon";
import BiggestFishBoard from "../components/leaderboards/biggest-fish/BiggestFishBoard";
import BiggestGrass from "../components/leaderboards/biggest-grass/BiggestGrass";
import BiggestMirror from "../components/leaderboards/biggest-mirror/BiggestMirror";
import HeatMapTest from "../components/maps/HeatMapTest";
import VenueMapContainer from "../components/maps/VenueMapContainer";

export default function PublicRoutes() {
  return (
    <>
      <Route exact path="/" component={SignIn} />
      <Route path="/forgot" component={Forgot} />
      <Route path="/sign-up" component={SignUp} />
      <Route path="/angler-year" component={AnglerOfTheYear} />
      <Route path="/biggest-three" component={BiggestFishBoard} />
      <Route path="/biggest-common" component={BiggestCommon} />
      <Route path="/biggest-mirror" component={BiggestMirror} />
      <Route path="/biggest-grass" component={BiggestGrass} />
      <Route path="/angler-entries/:anglerId" component={AnglerEntries} />
      <Route path="/heatmaptest" component={HeatMapTest} />
      <Route
        path="/heatmap/:venueId/:latitude/:longitude/:species?/:weight?/:days?/"
        component={VenueMapContainer}
      />
    </>
  );
}
