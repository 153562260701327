import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    img: {
        maxWidth: '100%'
    },
    close: {
        float: 'right'
    },
    header: {
        background: theme.palette.primary.main
    },
    scrollPaper: {
        alignItems: 'baseline'
    }
}))

export default function CatchDetail({ open, close, entry }) {
    const classes = useStyles()
    const { photo_url, species, weight } = entry

    const handleClose = () => {
        close()
    }

    return (
        <Dialog classes={{ scrollPaper: classes.scrollPaper }} fullScreen open={open} onClose={handleClose}>
            <DialogTitle className={classes.header}>
                { `${species} - ${weight} kg` }
                <IconButton onClick={handleClose} className={classes.close}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div>
                    <img className={classes.img} src={photo_url} />
                </div>
            </DialogContent>
        </Dialog>
    )
}
