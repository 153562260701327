import { Button } from "@material-ui/core";
import React, { useState } from "react";
import VenueList from "./VenueList";
import { Add, LocationCity } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useDb } from "../../contexts/DatabaseContext";

export default function Venues() {
  const { generateGeohashes } = useDb();
  const [loading, setLoading] = useState(false);

  const runGeoScript = async () => {
    setLoading(true);
    try {
      await generateGeohashes();
    } catch (err) {
      console.log(err.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <h1>Venues</h1>
      <Link to="/add-venue">
        <Button color="primary" variant="contained" startIcon={<Add />}>
          Add Venue
        </Button>
      </Link>
      <br />
      <br />
      <Button
        color="primary"
        variant="contained"
        startIcon={<LocationCity />}
        onClick={runGeoScript}
        disabled={loading}
      >
        Run Venue GeoHash Fix
      </Button>

      <VenueList />
    </div>
  );
}
