import React from "react";
import { Route } from "react-router-dom";
import IncomingCatches from "../components/admin/fishcatches/IncomingCatches";
import ApprovedCatches from "../components/admin/fishcatches/ApprovedCatches";
import SiteUsers from "../components/admin/users/SiteUsers";
import AppUsers from "../components/admin/users/AppUsers";
import AnglerOfTheYear from "../components/leaderboards/angler-of-year/AnglerOfTheYear";
import AllCatches from "../components/admin/fishcatches/AllCatches";
import Teams from "../components/admin/teams/Teams";
import Marshalls from "../components/admin/marshalls/Marshalls";
import Venues from "../components/venues/Venues";
import AddVenue from "../components/venues/AddVenue";
import VenueMapContainer from "../components/maps/VenueMapContainer";
import Baits from "../components/admin/baits/Baits";
import AddBait from "../components/admin/baits/AddBait";
import AddCatch from "../components/admin/fishcatches/AddCatch";
import Articles from "../components/admin/articles/Articles";
import ArticleDetail from "../components/admin/articles/ArticleDetail";
import Competitions from "../components/admin/competitions/Competitions";
import CompetitionDetail from "../components/admin/competitions/CompetitionDetail";
import CreateCompetition from "../components/admin/competitions/CreateCompetition";
import EditTeam from "../components/admin/teams/EditTeam";
import CompCatchDetail from "../components/admin/competitions/CompCatchDetail";
import EditBait from "../components/admin/baits/EditBait";
import VenueDetail from "../components/venues/VenueDetail";
import VenueScrapes from "../components/venues/VenueScrapes";
import CompTeamEntries from "../components/admin/competitions/CompTeamEntries";

export default function AdminRoutes() {
  return (
    <>
      <Route exact path="/" component={IncomingCatches} />
      <Route path="/incoming" component={IncomingCatches} />
      <Route path="/approved" component={ApprovedCatches} />
      <Route path="/site-users" component={SiteUsers} />
      <Route path="/app-users" component={AppUsers} />
      <Route path="/angler-year" component={AnglerOfTheYear} />
      <Route path="/all-catches" component={AllCatches} />
      <Route path="/teams" component={Teams} />
      <Route path="/edit-team/:teamId" component={EditTeam} />
      <Route path="/marshalls" component={Marshalls} />
      <Route path="/venues" component={Venues} />
      <Route path="/add-venue" component={AddVenue} />
      <Route
        path="/heatmap/:venueId/:latitude/:longitude"
        component={VenueMapContainer}
      />
      <Route path="/baits" component={Baits} />
      <Route path="/add-bait" component={AddBait} />
      <Route path="/edit-bait/:baitId" component={EditBait} />
      <Route path="/add-catch" component={AddCatch} />
      <Route path="/articles" component={Articles} />
      <Route path="/article/:articleId" component={ArticleDetail} />
      <Route path="/competitions" component={Competitions} />
      <Route path="/competition/:compId" component={CompetitionDetail} />
      <Route path="/create-competition" component={CreateCompetition} />
      <Route path="/comp-catch-detail/:catchId" component={CompCatchDetail} />

      <Route path="/venue-detail/:venueId" component={VenueDetail} />
      <Route path="/venue-scrapes" component={VenueScrapes} />

      <Route path="/team-entries/:compId" component={CompTeamEntries} />
    </>
  );
}
