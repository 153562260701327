import React from "react";
import { useDb } from "../../contexts/DatabaseContext";
import CarpDataGrid from "../../shared/datagrid/CarpDataGrid";
import { Avatar, Button } from "@material-ui/core";

export default function VenueCatches({ venueId }) {
  const { GetCollection, removeFieldFromDoc } = useDb();
  const venueCatches = GetCollection(
    "catches",
    [["venue_id", "==", venueId]],
    ["weight", "desc"]
  );

  console.log(venueCatches);

  const handleRemove = async (catchId) => {
    const confirm = window.confirm("Are you sure?");
    if (confirm) {
      await removeFieldFromDoc("catches", catchId, "venue_id");
    }
  };

  const rows =
    venueCatches &&
    venueCatches.map((c) => ({
      ...c,
    }));

  const columns = [
    {
      field: "photo_url",
      headerName: " ",
      width: 80,
      renderCell: (params) => {
        return <Avatar src={params.row.photo_url} />;
      },
    },
    { field: "display_name", headerName: "Angler", width: 200 },
    { field: "weight", headerName: "Weight", width: 140 },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        return (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleRemove(params.row.id)}
          >
            Remove
          </Button>
        );
      },
    },
  ];

  return <div>{rows && <CarpDataGrid data={rows} columns={columns} />}</div>;
}
