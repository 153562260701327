import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import CompCatchList from "./CompCatchList";

export default function CompCatches({ compId }) {
  const { GetCollection } = useDb();
  const catches = GetCollection(
    "catches",
    [["competition_ids", "array-contains", compId]],
    ["timestamp"]
  );
  console.log(catches);
  return <div>{catches && <CompCatchList catches={catches} />}</div>;
}
