import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import FishList from './FishList'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

export default function AllCatches() {
    const { GetAllCatches } = useDb()
    const fish = GetAllCatches()

    return (
        <div>
            <h1>All Catches</h1>
            <Link to="/add-catch">
            
            <Button
                variant="contained"
                color="primary"

            >
                Add Catch
            </Button>
            </Link>
            <FishList
                data={fish}
                allView={true}
            />
        </div>
    )
}
