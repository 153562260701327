import { Avatar, Card, CardHeader } from '@material-ui/core'
import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'

export default function TeamsThreeBoard() {
    const { GetTeamsLeaderboard } = useDb()
    const teams = GetTeamsLeaderboard()
    

    return (
        <div>
            <h1>Teams Leaderboard</h1>
            {
                teams && teams.map(t => (
                    <Card key={t.id}>
                        <CardHeader
                            title={t.team_name}
                            avatar={<Avatar src={t.team_logo}/>}
                            subheader={`${t.weight} kg`}
                        />
                    </Card>
                ))
            }
        </div>
    )
}
