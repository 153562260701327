import React, { useState } from "react";
import BackLink from "../../../shared/BackLink";
import { useDb } from "../../../contexts/DatabaseContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CarpDataGrid from "../../../shared/datagrid/CarpDataGrid";
import { Button } from "@material-ui/core";
import { functions } from "../../../firebase";

export default function CompTeamEntries() {
  const { compId } = useParams();
  const { GetTeamsEntries } = useDb();

  const entries = GetTeamsEntries(compId);

  console.log(entries);

  const [loading, setLoading] = useState(false);

  const handleRecalculate = async (entryId) => {
    const manuallyUpdateTeamCompEntries = functions.httpsCallable(
      "manuallyUpdateTeamCompEntries"
    );

    setLoading(true);

    await manuallyUpdateTeamCompEntries({
      teamEntryId: entryId,
      competitionId: compId,
    });

    setLoading(false);
  };

  const rows =
    entries &&
    entries.map((entry) => ({
      ...entry,
      teamId: entry.teamRef.id,
    }));

  const columns = [
    { field: "id", headerName: "Team Entry ID", width: 300 },
    { field: "combinedWeight", headerName: "Combined Weight", width: 200 },
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            disabled={loading}
            color="primary"
            onClick={() => handleRecalculate(params.row.id)}
          >
            Recalculate Scores
          </Button>
        );
      },
    },
  ];
  return (
    <div>
      <h1>Comp Team Entries</h1>
      <BackLink />
      {rows && <CarpDataGrid data={rows} columns={columns} />}
    </div>
  );
}
