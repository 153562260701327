function isValidCoordinates(lat, long) {
  // Latitude ranges from -90 to 90 and Longitude ranges from -180 to 180
  const latPattern = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
  const longPattern =
    /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;

  return latPattern.test(lat) && longPattern.test(long);
}

export function parseLatLong(latLongString) {
  const [lat, long] = latLongString.split(",").map(parseFloat);
  if (!isValidCoordinates(lat, long)) {
    throw new Error("Invalid latitude or longitude");
  }
  return {
    lat,
    long,
  };
}
