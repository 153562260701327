import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import CarpDataGrid from '../../../shared/datagrid/CarpDataGrid'

export default function SiteUsers() {
    const { GetSiteEmails } = useDb()
    const users = GetSiteEmails()
    console.log(users)

    const rows = users && users.map(u =>({
        id: u.id,
        linked: u.linked ? 'YES' : 'NO',
    }))

    const columns = [
        { field: 'id', headerName: 'Email:', width: 400 },
        { field: 'linked', headerName: 'Linked' , width: 200 },
    ]

    return (
        <div>
            <h1>Site Users</h1>
            <CarpDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
