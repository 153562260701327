import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { selectFieldProps, textFieldProps } from "../../../shared/constants";
import VenueMapPreview from "../../maps/VenueMapPreview";
import { parseLatLong } from "../../maps/mapsHelpers";
import { useDb } from "../../../contexts/DatabaseContext";
import Select from "react-select";
import { functions } from "../../../firebase";

export default function AddGeo({ open, close, fish }) {
  const { species, date, weight, photoUrl, angler } = fish;
  const { GetCollection } = useDb();
  const venues = GetCollection("venues");
  const [data, setData] = useState({});
  const [coords, setCoords] = useState({
    lat: 0,
    long: 0,
  });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setLoading(false);
    setData({});
    close();
  };

  const handleGoogleMapLatLng = (e) => {
    try {
      const coordinates = parseLatLong(e.target.value);
      setCoords(coordinates);
      setData({
        ...data,
        latitude: coordinates.lat,
        longitude: coordinates.long,
      });
    } catch (err) {
      window.alert("Invalid coordinates");
    }
  };

  const handleViewPortChange = (newViewPort) => {
    console.log(newViewPort);
    setData({
      ...data,
      latitude: newViewPort.latitude.toString(),
      longitude: newViewPort.longitude.toString(),
      zoom: newViewPort.zoom,
    });
    setCoords({
      lat: newViewPort.latitude,
      long: newViewPort.longitude,
    });
  };

  const handleVenueSelect = (v) => {
    setData({
      ...data,
      venueId: v.value,
      venue: v.label,
    });
  };

  const handleSubmit = async () => {
    const addGeoToCatch = functions.httpsCallable("addGeoToCatch");
    setLoading(true);
    const geoResult = await addGeoToCatch({
      catchId: fish.id,
      latitude: coords.lat,
      longitude: coords.long,
      venue: data.venue,
      venueId: data.venueId,
    });
    setLoading(false);
    if (geoResult.data.success) {
      handleClose();
    } else {
      window.alert(geoResult.data.message || "Error Saving Geo Catch Data");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Location to Catch</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <img src={photoUrl} width={"100%"} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              {species} - {weight}{" "}
            </Typography>
            <Typography>Angler: {angler}</Typography>
            <Typography>Caught: {date}</Typography>
          </Grid>
        </Grid>
        <Select
          options={venues}
          onChange={handleVenueSelect}
          name="venue"
          placeholder="Select Venue ..."
          className="carp-select"
          {...selectFieldProps}
        />
        <Card>
          <CardHeader title="Map" />
          <CardContent>
            <TextField
              {...textFieldProps}
              label="Paste Google Map LatLng"
              name="gmapLatLng"
              onChange={handleGoogleMapLatLng}
            />

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  {...textFieldProps}
                  name="latitude"
                  disabled
                  value={coords.lat}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...textFieldProps}
                  name="longitude"
                  disabled
                  value={coords.long}
                />
              </Grid>
            </Grid>
            <Typography>Zoom: {data.zoom}</Typography>
            {data.latitude && data.longitude && (
              <VenueMapPreview
                lat={coords.lat}
                lng={coords.long}
                viewPortUpdate={handleViewPortChange}
              />
            )}
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          disabled={
            !fish.id ||
            !coords.lat ||
            !coords.long ||
            !data.venue ||
            !data.venueId ||
            !data.zoom ||
            loading
          }
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
