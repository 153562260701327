import React, { useState } from "react";
import MapGL, { Source, Layer } from "react-map-gl";
import { heatMapLayer } from "./mapsConfig";
import "mapbox-gl/dist/mapbox-gl.css";
// added the following 6 lines.
import mapboxgl from "mapbox-gl";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
mapboxgl.workerClass = MapboxWorker;

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

export default function VenueMap({ latitude, longitude, geoJson }) {
  const [viewport, setViewport] = useState({
    latitude,
    longitude,
    zoom: 12,
    bearing: 0,
    pitch: 0,
  });

  return (
    <div style={{ height: "100vh" }}>
      <MapGL
        {...viewport}
        width="100"
        height="100"
        mapStyle="mapbox://styles/iwanzbiz/cli0jy7ot02cd01prgdrx1ou6"
        onViewportChange={setViewport}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        onMove={(evt) => setViewport(evt.viewState)}
      >
        <Source type="geojson" data={geoJson}>
          <Layer {...heatMapLayer} />
        </Source>
      </MapGL>
    </div>
  );
}
