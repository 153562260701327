import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDb } from "../../../contexts/DatabaseContext";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import BackLink from "../../../shared/BackLink";
import { textFieldProps } from "../../../shared/constants";
import FileUploader from "../../../shared/FileUploader";
import { Save } from "@material-ui/icons";

export default function EditBait() {
  const { baitId } = useParams();
  const { getStandardDoc, updateStandardDoc } = useDb();
  const [loading, setLoading] = useState(false);

  const [bait, setBait] = useState();

  const loadBait = async () => {
    const b = await getStandardDoc("baits", baitId);
    setBait(b);
  };

  useEffect(() => {
    return loadBait();
  }, [baitId]);

  if (!bait) {
    return <LinearProgress />;
  }

  const getFile = (file) => {
    setBait({
      ...bait,
      img_url: file.url,
    });
  };

  const handleSave = async () => {
    setLoading(true);
    await updateStandardDoc("baits", baitId, bait);
    setLoading(false);
    window.location.reload();
  };

  return (
    <div>
      <h1>Edit Bait</h1>
      <BackLink />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title={bait.title} />

            <CardContent>
              <TextField
                {...textFieldProps}
                value={bait.title}
                label="Title"
                name="title"
                onChange={(e) =>
                  setBait({
                    ...bait,
                    title: e.target.value,
                  })
                }
              />
              <FileUploader sendFile={getFile} fileType={"Bait Photo"} />
            </CardContent>
            <CardActions>
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                startIcon={<Save />}
                onClick={handleSave}
              >
                Save Changes
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          {bait.img_url && (
            <img src={bait.img_url} width={"100%"} alt={bait.title} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
