import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Chip,
  Button,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { CheckCircle, Person, RemoveCircle, Warning } from "@material-ui/icons";

export default function TeamMember({ memberId, teamId, idName, refName }) {
  const {
    getStandardDoc,
    updateStandardDoc,
    removeFieldFromDoc,
    addAnglerToFk7,
  } = useDb();
  const [user, setUser] = useState();

  const loadUser = async () => {
    const u = await getStandardDoc("users", memberId);
    setUser(u);
  };

  useEffect(() => {
    return loadUser();
  }, [memberId]);

  const handleLink = async () => {
    await updateStandardDoc("users", memberId, {
      team_id: teamId,
    });
    // await addAnglerToFk7(memberId);
    setUser({
      ...user,
      team_id: teamId,
    });
  };

  const handleRemove = async () => {
    const confirm = window.confirm(
      "are you sure you want to remove this team member?"
    );
    if (confirm) {
      await removeFieldFromDoc("users", memberId, "team_id");
      await removeFieldFromDoc("teams", teamId, refName);
      await removeFieldFromDoc("teams", teamId, idName);
      window.location.reload();
    }
  };

  return !user ? (
    <CircularProgress />
  ) : (
    <Card style={{ background: "#0a5b3a" }}>
      <CardHeader
        avatar={<Avatar src={user.photo_url} />}
        title={`${user.display_name || user.email}`}
        action={
          <IconButton onClick={handleRemove}>
            <RemoveCircle color="secondary" />
          </IconButton>
        }
      />
      <CardContent>
        Linked:{" "}
        {user.team_id === teamId ? (
          <Chip label="OK" color="primary" icon={<CheckCircle />} />
        ) : (
          <Button
            startIcon={<Warning />}
            variant="contained"
            color="secondary"
            onClick={handleLink}
          >
            Link Now
          </Button>
        )}
      </CardContent>
    </Card>
  );
}
