import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { baitTypes, textFieldProps } from "../../../shared/constants";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardMedia,
  Grid,
  TextField,
  CardHeader,
  CardContent,
  Button,
} from "@material-ui/core";
import Select from "react-select";
import FileUploader from "../../../shared/FileUploader";

export default function AddBait() {
  const history = useHistory();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { addStandardDoc, updateStandardDoc } = useDb();

  const handleText = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (v, e) => {
    setData({
      ...data,
      [e.name]: v.label,
    });
  };

  const getFile = (fileObj) => {
    setData({
      ...data,
      img_url: fileObj.url,
      img_blurhash: fileObj.blurHash,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const bait_id = await addStandardDoc("baits", { ...data });
      await updateStandardDoc("baits", bait_id, { bait_id });
      history.push("/baits");
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <h1>Add Bait</h1>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              {...textFieldProps}
              label="Title"
              name="title"
              onChange={handleText}
              required
            />
            <Select
              options={baitTypes}
              onChange={handleSelect}
              name="type"
              placeholder="Select bait type ..."
              className="carp-select"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardHeader title="Bait Image" />
              {data.img_url && (
                <CardMedia image={data.img_url} style={{ height: 250 }} />
              )}
              <CardContent>
                <FileUploader sendFile={getFile} fileType="Bait Image" />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <br />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          fullWidth
          size="large"
        >
          Submit
        </Button>
      </form>
    </div>
  );
}
