import { Button, Card, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { CloudDownload, Videocam } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        background: theme.palette.primary.main
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
}))

export default function CatchDetailViewOnly({ fish, open, close }) {
    const classes = useStyles()
    const { id, species, date, weight, photoUrl, uid, venue, angler } = fish;
    const { getCatchVids } = useDb()
    const [state, setState] = useState({
        error: null,
        loading: false,
    })

    const handleClose = () => {
        setState({
            error: null,
            loading: false
        })
        close()
    }

    const retrieveVideo = async () => {
        const vids = await getCatchVids(fish.id)
        console.log(vids)

    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle className={classes.dialogTitle}>
                {species} - {weight} 
            </DialogTitle>
            <DialogContent>
                <Typography>Angler: {angler}</Typography>
                <Typography>Caught: {date}</Typography>
                <Grid container spacing={3}>
                
                    <Grid item xs={12} md={8}>
                        <Card>
                            <CardMedia
                                component="image"
                                image={photoUrl}
                                className={classes.media}
                            />
                        </Card>
                    </Grid>
                    
                </Grid>
                {
                    state.error && <Typography color="secondary">{state.error}</Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<CloudDownload />}
                    href={photoUrl}
                    target="_blank"
                >
                    Download Photo
                </Button>
                
                <Button
                    onClick={handleClose}
                    disabled={state.loading}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}
